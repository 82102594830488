import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Datenschutz from "../components/datenschutz"

const SecondPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Datenschutz />
  </Layout>
)

export default SecondPage
